<style scoped>
.status-box-approved {
    font-weight: 600;
    background-color: #247ba0;
    color: white;
    font-family: nunito;
    border-radius: 5px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 12px;
}
.status-box-pending {
    font-weight: 600;
    background-color: #011c27;
    color: white;
    font-family: nunito;
    border-radius: 5px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 12px;
}
.id-box-share{
    font-weight: 600;
    background-color: #011c27;
    color: white;
    font-family: nunito;
    border-radius: 5px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 20px;
}
.button-new-client{
    border: 2px solid #247ba0;
    margin-right:30px; 
    background-color:#247ba0 !important;
    border-radius: 10px;
    color: white;
    font-family: "Inter", sans-serif !important;
    font-weight: 400 !important;
    font-size: 14px;
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .button-new-client:hover{
    border: 2px solid #011c27;
    margin-right:30px; 
    background-color: #ffffff !important;
    border-radius: 10px;
    color: #595959 !important;
    font-family: "Inter", sans-serif !important;
    font-weight: 400 !important;
    font-size: 14px;
    padding-top: 7px;
    padding-bottom: 7px;
  }
.title-shared {
  font-size: 40px;
  margin: 0 0 0px 0;
  color: #011c27;
  margin-bottom: 30px;
  font-family: nunito;
  font-weight: 600;
  pointer-events: none;
}
.dot-shared {
  pointer-events: none;
  color:#247ba0;
  font-family: nunito;
  font-weight: 600
}
.table_content_planning{
  margin-left: 60px;
  margin-right: 60px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 25px;
  padding-bottom: 15px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  background-color: white;
}
.share_content_title{
    font-weight: bold;
    color: #ffffff;
    text-transform: uppercase;
    padding-top: 80px; 
    font-size: 60px;
    text-align: center;
}
.share_content_subtitle{
   font-weight: bold;
    color: #3354e1;
    text-transform: uppercase;
    padding-bottom: 30px; 
    font-size: 60px;
    text-align: center;
}
.dot_content_title{
    color: #3354e1;
}
.div_share_content{
  padding-bottom: 60px;
}
.button_share_content{
    border: 2px solid #595959;
    position: relative;
    background-color:transparent !important;
    display: inline-flex;
    vertical-align: middle;
    border-radius: 10px;
    color: #011c27 !important;
    font-family: "Inter", sans-serif !important;
    font-weight:400 !important;
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 14px;
}
.button_share_content:hover{
    border: 2px solid #011c27;
    position: relative;
    background-color:#011c27 !important;
    display: inline-flex;
    vertical-align: middle;
    border-radius: 10px;
    color: white !important;
    font-family: "Inter", sans-serif !important;
    font-weight:400 !important;
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 14px;
}
</style>
<script>
//const brandController = require('@/controllers/endpoint/brands')
import Swal from "sweetalert2";
import "@/assets/scss/buttons_share.scss";
const postsController = require('@/controllers/endpoint/content_posts')
const clientUserController = require('@/controllers/endpoint/user')
const commentController = require('@/controllers/endpoint/comment')


export default {
  data() {
    return {
            basepathENDPOINT: process.env.VUE_APP_BASEPATH_ENDPOINT,
            contentId:this.$route.params.idContent,    
            posts_ads:[],
            posts_organic:[],
            idClient:'',
            emailClient:'',
            passClient:'',
            login:0,
            responseForm:0,
            type_param : this.$route.params.type,
           
            Comment_data:{
                id_post:'',
                comment:'',
                post_content_planning_id:'',
            }
  };
  },
  computed: { 
     disabledlogin() {
      return (
        this.emailClient == "" ||
        this.passClient == ""
      );
    },
  },
  created() {
   this.postsData()
  },
    mounted() {
    if (localStorage.email || localStorage.id) {
      this.emailClient = localStorage.email;
      this.idClient = localStorage.id;
      this.login = 1;
    }else{
      this.login = 0;
    }
  },
  methods: {
    send_Comment(){
        const comment_response = commentController.create(this.Comment_data, this.idClient, this.emailClient);
        comment_response.then((response) => {   //Succesfully result
        response
        const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-2"
        },
        buttonsStyling: false
      });
        swalWithBootstrapButtons.fire(
              "Send comment!",
              "success"
            );
            this.close_modalcomment()
    })
      .catch((err) => {    //Error result
        console.error(err);
      });
            
    },
     open_modalcomment(creative){
      console.log(this.Comment_data)
      this.Comment_data = {
         id_post: creative.id,
         comment:'',
         post_content_planning_id:creative.content_planning_id,
      }
         console.log(this.Comment_data)
      this.$refs['comment-popup-modal'].show()
    },
     close_modalcomment(){
        this.$refs['comment-popup-modal'].hide()
    },
    open_modaluser(){
      this.responseForm = 0
      this.emailClient = '';
      this.passClient = '';
      this.$refs['user-login-modal'].show()
    },
    close_modaluser(){
        this.$refs['user-login-modal'].hide()
    },
    logoutClient(){
         localStorage.removeItem('email');
         localStorage.removeItem('id');
         this.login = 0
     },
  validateUser(){
    const clientResponse = clientUserController.validateClient(this.emailClient, this.passClient);
        clientResponse.then((response) => {
        console.log(response.data.response)
        if(response.data.status == 1){
          this.idClient = response.data.user.id;
          localStorage.id = this.idClient;
          localStorage.email = this.emailClient;
          this.login = 1
          this.responseForm = 0
          this.close_modaluser()
        }else if(response.data.response == 0){
          this.responseForm = "User is not valid"
          this.login = 0
          this.responseForm = 1
        }
    })
      .catch((err) => {    //Error result
        console.error(err);
      });
  },
  postsData(){
        const postContent = postsController.list(this.contentId);
        postContent.then((response) => {
        this.posts_ads = response.data.filter((post) => post.type == 1);
        this.posts_ads = this.posts_ads.filter((post) => post.image_url !== null);

        this.posts_organic = response.data.filter((post) => post.type == 0);
        this.posts_organic = this.posts_organic.filter((post) => post.image_url !== null);
    })
      .catch((err) => {    //Error result
        console.error(err);
      });
  },
    statusAll_ads(type, status){
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-2"
        },
        buttonsStyling: false
      });
      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          confirmButtonText: "Yes, approve all ads creatives",
          cancelButtonText: "No, cancel!",
          showCancelButton: true
        })
        .then(result => {
        
        if (result.value) {
          
        const approveAll = postsController.status_all(type, status, this.posts_ads, this.idClient, this.emailClient);
        approveAll.then((response) => {   //Succesfully result
        response
        swalWithBootstrapButtons.fire(
              "Approve All creatives!",
              "The ads creatives have been approved",
              "success"
            );
           this.postsData()
    })
      .catch((err) => {    //Error result
        console.error(err);
      });
            
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "The ads creative is safe",
              "error"
            );
          }
        });
  },
  statusAll_organic(type, status){
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-2"
        },
        buttonsStyling: false
      });
      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          confirmButtonText: "Yes, approve all organic creatives",
          cancelButtonText: "No, cancel!",
          showCancelButton: true
        })
        .then(result => {
        
        if (result.value) {
          
        const approveAll = postsController.status_all(type, status, this.posts_ads, this.idClient, this.emailClient);
        approveAll.then((response) => {   //Succesfully result
        response
        swalWithBootstrapButtons.fire(
              "Approve All creatives!",
              "The organic creatives have been approved",
              "success"
            );
           this.postsData()

    })
      .catch((err) => {    //Error result
        console.error(err);
      });
            
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "The organic creatives is safe",
              "error"
            );
          }
        });
  },
  changeStatus(creative, status){
    let select
    if(status ==  1){
      select = "Approve"
    }else if( status ==  2){
     
      select = "Disapprove"
    }
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-2"
        },
        buttonsStyling: false
      });
      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          confirmButtonText: `Yes, ${select} creative #${creative.id}!`,
          cancelButtonText: "No, cancel!",
          showCancelButton: true
        })
        .then(result => {
        
        if (result.value) {

        const changeStatus = postsController.change_status(creative, status, this.idClient, this.emailClient);
        changeStatus.then((response) => {   //Succesfully result
        response
        swalWithBootstrapButtons.fire(
              "Approve!",
              `Your creative #${creative.id} has been disapprove.`,
              "success"
            );
           this.postsData()
    })
      .catch((err) => {    //Error result
        console.error(err);
      });
            
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              `Your creative creative #${creative.id} is safe`,
              "error"
            );
          }
        });
  } 
  }
};
</script>
<template>
<div style="margin-right:30px; margin-left:30px; padding-top:60px;" class="div_share_content">
  <div v-if="type_param == 'all' || type_param == 'ads'" class="row">
      <div  class="col-lg-6 text-lg-left">
          <h5 class="title-shared">Content planning<br><span class="dot-shared">Ads design</span>.</h5>
      </div>
      <div  class="col-lg-6 text-lg-right">
      <button v-if="login == 0" style="padding-left:15px; padding-right:15px;" class="button-new-client"  v-on:click="open_modaluser()">Access login</button>
            <button v-if="login == 1" style="padding-left:15px; padding-right:15px;" class="button-new-client"  v-on:click="logoutClient()">Logout</button>
      </div>
      <br>
      <div class="button-div col-lg-12 text-lg-left">
      <button v-on:click="statusAll_ads(1, 1)" class="button-new-client">Approve all ads creatives</button>
      </div>
      <b-modal title="Login Client" id="login-popup-form" ref="user-login-modal" hide-footer centered>
                      <div class="form-group">
                        <p style="color:red; font-weight:bold;" v-if="responseForm == 1">User is not valid</p>
        <label for="name">Email</label>
                
                <input
                 v-model="emailClient"
                  type="email"
                  class="form-control"
                  id="name"
                  placeholder="Enter your email"
                /></div>
                 <div class="form-group">
                        <label for="name">Password</label>
                 <input
                 v-model="passClient"
                  type="password"
                  class="form-control"
                  id="password"
                  placeholder="Enter your password"
                />
                </div>
                <button :disabled="disabledlogin" v-on:click="validateUser()" class="button_share_content">Login</button>
      </b-modal>
            <b-modal title="Comment creative" id="comment-popup-form" ref="comment-popup-modal" hide-footer centered>
                      <div class="form-group">
                        <p style="color:red; font-weight:bold;" v-if="responseForm == 1">User is not valid</p>

                <textarea
                 v-model="Comment_data.comment"
                  type="textarea"
                  class="form-control"
                  id="name"
                  rows="4" 
                  placeholder="Enter your comment"
                /></div>
                <button v-on:click="send_Comment()" class="button_share_content">Send Comment</button>
      </b-modal>
    </div>
    <div  v-if="type_param == 'all' || type_param == 'ads'" style="padding-top:50px;" class="table-responsive">
              <table class="table mb-0">
                <thead>
                  <tr>
                    <th width="2%" style="text-align: center;">ID</th>
                    <th width="20%">UTM</th>
                    <th width="20%">Creative Info</th>
                    <th>Format</th>
                    <th width="20%">Status</th>
                    <th>Creative</th>
                    <th  v-if="login == 1" width="15%">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(post, index) in posts_ads" :key="index">
              <td><span class="id-box-share">{{post.id}}</span></td>
              <td>
                {{post.utm}}
              </td>
              <td>
                <p style="margin-bottom:0px; font-weight:bold;">COPY IN</p>
                <span v-html="post.copy_in">
                {{post.copy_in}}
                </span>
                <p style="margin-bottom:0px; margin-top:15px; font-weight:bold;">COPY OUT</p>
                <span v-html="post.copy_out">
                {{post.copy_out}}
                </span>
              </td>
              <td>
                <span>{{post.format}}</span>
              </td>
              <td  v-if="post.type == 1"><span class="status-box-pending" v-if="post.status == 0">Pending</span><span v-if="post.status == 1" class="status-box-approved">Approved by {{post.emailClient_status}}</span>
              <br v-if="post.status == 1"><br v-if="post.status == 1"><span v-if="post.status == 1" class="status-box-approved">{{post.status_date}}</span><span v-if="post.status == 2" class="status-box-approved">Disapproved by {{post.emailClient_status}}</span><br v-if="post.status == 2"><br v-if="post.status == 2"><span v-if="post.status == 2" class="status-box-approved">{{post.status_date}}</span></td>
              <td>
                <img v-if="post.image_url !== null" :src="`${basepathENDPOINT}${post.image_url}`"  style="width:100%; height:auto;"/>                   
              </td>
              <td  v-if="login == 1">
                <button v-if="post.status == 2 || post.status == 0"  style="padding-left:17px; padding-right:17px; margin-bottom:15px" v-on:click="changeStatus(post, 1)" class="button_share_content">APPROVE CREATIVE</button>
                <button v-if="post.status == 1 || post.status == 0"  style="margin-bottom:15px" v-on:click="changeStatus(post, 2)" class="button_share_content">DISAPPROVE CREATIVE</button>
                <button style="padding-left:27px; padding-right:27px;" v-on:click="open_modalcomment(post)" class="button_share_content">SEND COMMENT</button>
              </td>
            </tr>
                </tbody>
              </table>
            </div>
  <div  v-if="type_param == 'all' || type_param == 'organic'" class="row">
      <div  class="col-lg-6 text-lg-left">
          <h5 class="title-shared">Content planning<br><span class="dot-shared">Organic design</span>.</h5>
      </div><br>
      <div class="button-div col-lg-12 text-lg-left">
      <button  v-on:click="statusAll_organic(0, 1)" class="button-new-client" style="">Approve all organic creatives</button>
      </div>
      
    </div>
    <div v-if="type_param == 'all' || type_param == 'organic'" style="padding-top:50px;" class="table-responsive">
              <table class="table mb-0">
                <thead>
                  <tr>
                    <th width="2%" style="text-align: center;">ID</th>
                    <th width="20%">UTM</th>
                    <th width="20%">Creative Info</th>
                    <th>Format</th>
                    <th width="20%">Status</th>
                    <th>Creative</th>
                    <th width="15%">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(post, index) in posts_organic" :key="index">
              <td><span class="id-box-share">{{post.id}}</span></td>
              <td>
                {{post.utm}}
              </td>
              <td>
                <p style="margin-bottom:0px; font-weight:bold;">COPY IN</p>
                <span v-html="post.copy_in">
                {{post.copy_in}}
                </span>
                <p style="margin-bottom:0px; margin-top:15px; font-weight:bold;">COPY OUT</p>
                <span v-html="post.copy_out">
                {{post.copy_out}}
                </span>
              </td>
              <td>
                <span>{{post.format}}</span>
              </td>
              <td  v-if="post.type == 0"><span class="status-box-pending" v-if="post.status == 0">Pending</span><span v-if="post.status == 1" class="status-box-approved">Approved by {{post.emailClient_status}}</span>
              <br v-if="post.status == 1"><br v-if="post.status == 1"><span v-if="post.status == 1" class="status-box-approved">{{post.status_date}}</span><span v-if="post.status == 2" class="status-box-approved">Disapproved by {{post.emailClient_status}} </span><br v-if="post.status == 2"><br v-if="post.status == 2"><span v-if="post.status == 2" class="status-box-approved">{{post.status_date}}</span></td>
              <td>
                <img v-if="post.image_url !== null" :src="`${basepathENDPOINT}${post.image_url}`"  style="width:100%; height:auto;"/>                   
              </td>
              <td>
                <button v-if="post.status == 2 || post.status == 0"  style="padding-left:17px; padding-right:17px; margin-bottom:15px" v-on:click="changeStatus(post, 1)" class="button_share_content">APPROVE CREATIVE</button>
                <button v-if="post.status == 1 || post.status == 0"  style="margin-bottom:15px" v-on:click="changeStatus(post, 2)" class="button_share_content">DISAPPROVE CREATIVE</button>
                <button style="padding-left:27px; padding-right:27px;" class="button_share_content">SEND COMMENT</button>
              </td>
            </tr>
                </tbody>
              </table>
            </div>
</div>
</template>
